import Constant from "../common/Constant";


export function SetDownloadUrls(chanKey, callBack) {
    //獲取數據
    fetch('/data/download.json')
        .then((res) => res.json())
        .then((data) => {
            Constant.ANDROID_DOWN_LOAD_URL = data.android_download_url;
            Constant.IOS_DOWN_LOAD_URL = data.ios_download_url;
            Constant.IOS_TEST_FLIGHT_URL = data.ios_test_flight_url;
            Constant.IOS_WEB_CLIP_URL = data.ios_web_clip_url;
            Constant.REPAIR_URL = data.repair_url;
            Constant.REPAIR_FLAG = data.repair_flag;
            Constant.IOS_GET_UUID_URL = data.ios_get_uuid_url;
            Constant.JUMP_NEW_URL = data.jump_get_url;
            Constant.REPORT_VIEW_URL = data.report_view_url;
            Constant.API_DOMAIN = data.api_domain;
            Constant.API_KEY = data.api_key;
            /////進行上報
            if (Constant.REPORT_VIEW_URL) {
                reportCurrentUrl(Constant.REPORT_VIEW_URL, chanKey, 'tkdownloadweb');
            }
            if (callBack) {
                callBack();
            }
        })
}

export function isWeiXin() {
    let ua = window.navigator.userAgent.toLowerCase();
    // eslint-disable-next-line
    if (ua.match(/MicroMessenger/i) == "micromessenger"||ua.match(/WeiBo/i) == "weibo") {
        return true;
    }
    else if(isQQApp()) {
        return true;
    }
    else{
        return false;
    }
}

export function isQQApp() {
    let isIosQQ = ( /(iPhone|iPad|iPod|iOS)/i.test(window.navigator.userAgent) && /\sQQ/i.test(window.navigator.userAgent));
    let isAndroidQQ = ( /(Android)/i.test(window.navigator.userAgent) && /MQQBrowser/i.test(window.navigator.userAgent) && /\sQQ/i.test((window.navigator.userAgent).split('MQQBrowser')));

    if(isIosQQ || isAndroidQQ){
        return true;
    }
    return false;
}

export function Browser() {
    let u = navigator.userAgent;
    return {
        trident: u.indexOf('Trident') > -1,
        presto: u.indexOf('Presto') > -1,
        webKit: u.indexOf('AppleWebKit') > -1,
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1,
        mobile: !!u.match(/AppleWebKit.*Mobile.*/),
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
        android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1,
        iPhone: u.indexOf('iPhone') > -1,
        iPad: u.indexOf('iPad') > -1,
        Safari: u.indexOf('Safari') > -1,
        weiXin: u.indexOf('MicroMessenger') > -1,
        qq: u.match(/\sQQ/i) == " qq"
    }
}

export function reportCurrentUrl(url, chanKey, logKey, logType = '') {
    let wxFlag = isWeiXin();
    let wf = '0';
    if (wxFlag) {
        wf = '1';
    }
    let obj = {x_log_key: logKey, url: window.location.href, log_type: logType, wx_flag: wf, chan_key: chanKey};
    let fetchOption =
        {
            body: JSON.stringify(obj), // must match 'Content-Type' header
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, same-origin, *omit
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            method: 'post', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin//设置是否跨域请求cors跨域no-cors不跨域
            redirect: 'follow', // manual, *follow, error
        };
    // 开始所需数据的下载
    fetch(url, fetchOption)
        .then(response => response.json())
        .then(responseJson => {

        });
}

///根據當前的url地址，返回參數的json對象
export function GetUrlParams() {
    for (var e = window.location.href, t = e.indexOf("?") + 1, n = (e || "?").substring(t).replace(/\+/g, "%20").split("&"), a = {}, r = 0; r < n.length; r++) {
        let i = n[r].split("="), o = decodeURIComponent(i[0] || ""), l = decodeURIComponent(i[1] || "");
        o && l && (void 0 === a[o] ? a[o] = l : "object" == typeof a[o] ? a[o].push(l) : a[o] = [a[o], l])
    }
    return a
}

////判斷是否需要jump跳轉
export function checkNeedJump() {
    let keys = GetUrlParams();
    if (keys.jump) {
        return decodeURIComponent(keys.jump);
    }
    //沒有參數，
    return '';
}

export function setJumpUrlParam(appid = '') {
    setTimeout(() => {
        if (Constant.JUMP_NEW_URL) {
            let obj = {appid: appid, origin_url: window.location.href};
            let fetchOption =
                {
                    body: JSON.stringify(obj), // must match 'Content-Type' header
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, same-origin, *omit
                    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                    method: 'post', // *GET, POST, PUT, DELETE, etc.
                    mode: 'cors', // no-cors, cors, *same-origin//设置是否跨域请求cors跨域no-cors不跨域
                    redirect: 'follow', // manual, *follow, error
                };
            // 开始所需数据的下载
            fetch(Constant.JUMP_NEW_URL, fetchOption)
                .then(response => response.json())
                .then(responseJson => {
                    if (responseJson.code === 0) {
                        let j = responseJson.jump;
                        if (j && j !== '') {
                            window.location.href = j;
                        }
                        /*let newUrls = j.split('/');
                        if(newUrls.length >= 3){
                            Constant.NEW_BACK_URL = newUrls[0] + '//' + newUrls[2];
                        }*/


                    }
                });
        }
    }, 100);

}

export function CheckMobilePhone(phone) {
    let objRegExp = new RegExp('(^(?:\\+?86-)|^(?:\\+?832-)|^(?:\\+?853-))?1(?:3\\d{3}|5[^4\\D]\\d{2}|8\\d{3}|7(?:[35678]\\d{2}|4(?:0\\d|1[0-2]|9\\d))|9[189]\\d{2}|66\\d{2})\\d{6}$');
    return objRegExp.test(phone);
}

export function CheckPhone(areaCode, phone) {
    if (phone === '') {
        return false;
    }
    return CheckMobilePhone(areaCode.concat('-').concat(phone));
}

export function CheckNumber(number) {
    return new RegExp('^[0-9]*$').test(number);
}

export function subDevice(uuid) {
    return uuid.substr(0, 6).concat("******").concat(uuid.substr(uuid.length - 6, uuid.length + 1));
}
