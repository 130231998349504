import React from 'react';
import '../css/Toast.css'

class Toast extends React.Component{
    constructor(props) {
      super(props);
        this.state = {
            show: false
        };
    }

    show(msg){
        this.setState({
            show: true,
            msg: msg
        });
        this.hidden();
    }

    hidden(){
        setTimeout(()=>{
            this.setState({
                show: false,
            })
        },3000)
    }

    render () {
        let {show,msg} = this.state;
        return show && <div className="popup">
            <div className="popupCenter">{msg || "請使用手機瀏覽器下載"}</div>
        </div>
    }
}

export default Toast
