import React from 'react';
import '../css/OpenInBrowserDialog.css'
import imgWx from '../image/icon_wxNotice.png';


class OpenInBrowserDialog extends React.Component{
    // eslint-disable-next-line
    constructor(props) {
        super(props);
    }

    render () {
        return <div className="bg">
            <img className="imgCenter" src={imgWx} alt="wx"/>
        </div>
    }
}

export default OpenInBrowserDialog
