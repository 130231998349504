
export default {
    API_DOMAIN: '',
    API_KEY: '',
    Email: '91xjkf@gmail.com',
    ANDROID_DOWN_LOAD_URL: '',
    IOS_DOWN_LOAD_URL: '',
    IOS_TEST_FLIGHT_URL: '',
    IOS_WEB_CLIP_URL:'',
    IOS_GET_UUID_URL:'',
    REPAIR_URL:[],
    REPAIR_FLAG:true,
    REPORT_VIEW_URL:'',
    JUMP_NEW_URL:'',

    NEW_BACK_URL:'..',

    hiddenProperty: 'hidden' in document ? 'hidden' :
        'webkitHidden' in document ? 'webkitHidden' :
            'mozHidden' in document ? 'mozHidden' :
                null,
    VIDEOS:[
        {
            id: 1,
            title: '美女欲拒还迎，面对激情反而迎男而上',
            bg: '',
            url: '../video/tv1/1500kb/index.m3u8',
            playing: false, // 1 正在播放 0 暫停
            startPlay: false,
        },
        {
            id: 3,
            title: '高清自拍清纯美女学生妹制服技术太棒了，吃不够大jj，淫叫不断！',
            bg: '',
            url: '../video/v2/playlist.m3u8',
            playing: false, // 1 正在播放 0 暫停
            startPlay: false,
        },
        {
            id: 4,
            title: '黑丝长腿嫩乳翘臀美女露逼诱惑',
            bg: '',
            url: '../video/v3/playlist.m3u8',
            playing: false, // 1 正在播放 0 暫停
            startPlay: false,
        },
    ],
}

