import React, {Component} from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css'
import {HlsSourceHandler} from 'videojs-contrib-hls';

export default class VideoPlayer extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        videojs.getTech('html5').registerSourceHandler(HlsSourceHandler('html5'), 0);
        let _this = this
        this.player = videojs(this.videoContainer, {
            ...this.props,
            // flash: {
            //   swf: Swf,
            // },
        }, function () {
            let player = this
            let props = _this.props
            let sources = _this.sources

            // 播放器加载成功的回调
            if (!!props.onReady) {
                props.onReady(props)
            }

            // 修正使用多码流播放时自动播放失效的BUG
            // if (!!props.autoplay) {
            //   setInterval(() => {
            //     player.play();
            //   }, 100)
            // }

            // 判断是否是多码流，单码流调用video.js播放器播放，多码流使用插件播放
            if (player && props.playing) {
                setTimeout(() => {
                    player.play();
                }, 100)
            }
        })
    }

    componentWillUnmount() {
        // 销毁播放器
        if (this.player) {
            this.player.dispose()
        }
    }

    onVideoEnd = (e) => {
        this.props.onEnded && this.props.onEnded(e);
    }
    onVideoProgress = (e) => {
        this.props.onProgress && this.props.onProgress(e);
    }

    render() {
        return (
            <div>
                <video
                    ref={node => this.videoContainer = node}
                    width={this.props.width}
                    height={this.props.height}
                    onEnded={this.onVideoEnd}
                    controls={this.props.controls}
                    onProgress={this.onVideoProgress}
                    className="video-js"
                ></video>
            </div>
        )
    }
}

VideoPlayer.propTypes = {
    sources: PropTypes.object.isRequired,  // 视频流地址
    controls: PropTypes.bool,  //是否有组件控制
    onReady: PropTypes.func,   // 播放器加载成功时的回调
    onEnded: PropTypes.func,   // 播放器播放完成的回调
    onError: PropTypes.func,   // 播放器播放错误的回调
    onProgress: PropTypes.func,   // 播放器播放播放进度
}

