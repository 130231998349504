import React from 'react';
import ReactDOM from 'react-dom';
import App from './download/App';
import {Browser} from "./common/ComFunc";

let browser = Browser();
let isMobile = browser.mobile;
if(!isMobile){
    let urls = window.location.href.split("?");
    if(urls.length > 1){
        window.location.href = "/downloadApp/index.html?" + urls[1];
    }
    else {
        window.location.href = "/downloadApp/index.html";
    }
}
else {
    ReactDOM.render(<App/>, document.getElementById('root'));
}
